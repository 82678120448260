var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    attrs: {
      "type": "border-info card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info",
    attrs: {
      "title": "",
      "class-header": "card-header bg-light-info"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.auditLog')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-table-lite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "sticky-header": true,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "items": _vm.auditLogs,
      "fields": _vm.tableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(paxUpdated)",
      fn: function fn(_ref) {
        var _item$modifiedBy, _item$modifiedBy2;
        var item = _ref.item;
        return [_c('div', {
          staticClass: "fw-700 text-nowrap"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$modifiedBy = item.modifiedBy) === null || _item$modifiedBy === void 0 ? void 0 : _item$modifiedBy.firstName) + " " + _vm._s(item === null || item === void 0 ? void 0 : (_item$modifiedBy2 = item.modifiedBy) === null || _item$modifiedBy2 === void 0 ? void 0 : _item$modifiedBy2.lastName) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.action) + " ")])];
      }
    }, {
      key: "cell(updatedData)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.newValue ? _c('div', [item.newValue ? [_vm._v(" " + _vm._s(_vm.$te(item.newValue) ? _vm.$t(item.newValue) : item.newValue) + " ")] : !item.action.includes('BALANCE') ? [_c('div', _vm._l(JSON.parse(item.newValue), function (newVal, key) {
          return _c('div', {
            key: key
          }, [_vm.checkTypeValueAuditLog(newVal) ? _vm._l(newVal, function (val, key2) {
            return _c('div', {
              key: key2
            }, [_vm._v(" " + _vm._s(key2) + ": " + _vm._s(val) + " ")]);
          }) : _c('span', [_vm._v(" " + _vm._s(key) + ": " + _vm._s(newVal) + " ")])], 2);
        }), 0)] : Number(item.newValue) ? [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.newValue)))])] : [_c('span', [_vm._v(_vm._s(item.newValue))])]], 2) : _vm._e()];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).dateTime) + " ")])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }